


















import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import ContactForm from '@/components/ContactForm.vue'
import { Contact } from '@/interfaces'

export default Vue.extend({
  components: { Nav, ContactForm },
  data() {
    return {
      contact: null as Contact | null,
    }
  },
  computed: {
    contactInfos(): string {
      let contactName = ''
      if (this.contact?.firstName) {
        contactName += this.contact?.firstName
      }
      if (this.contact?.lastName) {
        contactName += ' ' + this.contact?.lastName
      }
      return contactName
    },
  },
  methods: {
    contactCreated() {
      this.$router.push({
        name: 'contacts',
      })
    },
    updateContactInfos(contact: Contact) {
      this.contact = contact
    },
  },
})
